import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";

const MarketingDlaEcommercePage = ({ location: { pathname } }) => {
	return (
		<Layout
			seo={{
				title: "Marketing dla e-commerce, sprzedażowe kampanie Google i Facebook Ads",
				description:
					"Skuteczne kampanie reklamowe w Google Ads i na Facebooku dla e-commerce. Realizujemy  marketing dla sklepów internetowych. Zaufaj certyfikowanym specjalistom Google i Facebook Ads. Zwiększymy Twoją sprzedaż w e-commerce. Marketing dla e-commerce z ekspertami.",
				href: pathname,
				lang: "pl",
			}}
		>
			<span className="anchor" id="home"></span>
			<div className="subpage-header subpage-header--single-offer">
				<h1 className="subpage-header__title">
					Marketing dla <br /> <span>e-commerce</span>
				</h1>
			</div>

			<div className="single-offer-menu-container">
				<div className="row">
					<div className="col-lg-3">
						<Link to="/uslugi/#specjalizacje">
							<div className="back-wrapper">
								<span className="back-wrapper__arrow"></span>
								<p className="back-wrapper__title">Usługi</p>
							</div>
						</Link>
						<p className="nav-title">Specjalizacje:</p>
						<ul>
							<li className="current">
								<a href="#home">
									Marketing dla <br /> e-commerce
								</a>
							</li>
							<li>
								<Link to="/marketing-dla-deweloperow/">
									Marketing dla deweloperów
								</Link>
							</li>
							<li>
								<Link to="/marketing-produktow-inwestycyjnych/">
									Marketing produktów <br /> inwestycyjnych
								</Link>
							</li>
							<li>
								<Link to="/marketing-dla-hoteli/">
									Marketing dla hoteli
								</Link>
							</li>
							{/* <li>
                        <a href="/">Marketing dla gamingu</a>
                        </li> */}
						</ul>
					</div>
				</div>
			</div>

			<section className="single-offer-section-text-left">
				<div className="row">
					<div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									Partner <br /> w sukcesie
								</h2>
								<p className="text-col__desc">
									Twój rozwój to nasz rozwój. Twój zysk to
									nasz zysk. #whenwewin to nasza filozofia
									wspólnego partnerstwa w sukcesie.
									Proponujemy Ci najnowsze rozwiązania z
									dziedziny reklamy dla e-commerce,
									najbardziej aktualną wiedzę oraz pakiet
									wielu lat naszego doświadczenia w reklamie
									dla sklepów internetowych. Jako agencja z
									najwyższą specjalizacją Google - Google
									Premier Partner, z zespołem 5 doświadczonych
									specjalistów Google i Facebook Ads
									wyspecjalizowanych w reklamie dla
									e-commerce, nie pozwolimy Twojej firmie się
									zatrzymać. Działamy dalej, działamy więcej!
								</p>
								<Link
									to="/win-win/"
									className="btn btn-line text-col__button"
								>
									filozofia win-win
								</Link>
							</div>
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/ecommerce-marketing-img-1.jpg")}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-text-right">
				<div className="row">
					<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/ecommerce-marketing-img-2.jpg")}
									alt=""
								/>
							</div>
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									ROI Masters
								</h2>
								<p className="text-col__desc">
									Nie będziemy tego ukrywać - mamy bzika na
									punkcie analityki. Zdajemy sobie sprawę z
									tego, że ROI i ROAS to cel istnienia każdego
									sklepu e-commerce oraz naszej agencji.
									Zwracamy uwagę na marżowość Twoich
									produktów, sezonowość, a prowadzone
									działania optymalizujemy pod Twój zysk - nie
									przychód. Nie lubimy stać biernie, a
									optymalizacja to nasze drugie imię. To
									właśnie z takim nastawieniem pomogliśmy już
									dziesiątkom sklepów rozwinąć swoje skrzydła
									z budżetów inwestycyjnych kilku, do kilkuset
									tysięcy złotych miesięcznie i/lub otwierając
									je na ekspansję na nowe, zagraniczne rynki.{" "}
									<br /> A później… skalujemy!
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-icons">
				<div className="row">
					<div className="col-xl-8 col-lg-9 offset-xl-3 offset-lg-3">
						<div className="row justify-content-center">
							<div className="col-md-6 item-col">
								<div className="item">
									<span className="item__icon icon icon--ecommerce-1"></span>
									<p className="item__title">
										Najwyższe <br /> odznaczenie <br />{" "}
										Google Premier <br /> Partner
									</p>
								</div>
							</div>
							<div className="col-md-6 item-col">
								<div className="item">
									<span className="item__icon icon icon--ecommerce-2"></span>
									<p className="item__title">
										5 doświadczonych <br /> specjalistów{" "}
										<br /> Google i Facebook Ads
									</p>
								</div>
							</div>
							<div className="col-md-6 item-col">
								<div className="item">
									<span className="item__icon icon icon--ecommerce-3"></span>
									<p className="item__title">
										Bezpłatny audyt obecnej <br />
										kampanii Google <br />i Facebook Ads
									</p>
								</div>
							</div>
							<div className="col-md-6 item-col">
								<div className="item">
									<span className="item__icon icon icon--ecommerce-5"></span>
									<p className="item__title">
										Partnerstwo w sukcesie!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-clients">
				<div className="row">
					<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
						<div className="section-header aligncenter">
							<span className="section-header__small-text">
								Wiemy, jak prowadzić kampanie inwestycyjne
							</span>
							<h2 className="section-header__title">
								Zaufali nam
							</h2>
						</div>
						<div className="row align-items-center justify-content-center">
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/thailand.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/empik.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/komputronik.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/ibis-styles.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/techland.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/greenpoint.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/steelseries.png")}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-6 client-col">
								<div className="client-col__logo-wrapper">
									<img
										className="img-fluid client-col__logo"
										src={require("../assets/img/clients/pigment.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-case-study">
				<div className="row">
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<h2 className="case-col__title">
							Sprawdź nasze przykładowe case studies produktów
							e-commerce
						</h2>
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/ecommerce-marketing-case-1.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								E-COMMERCE - BRANŻA TEKSTYLNA
							</p>
							<p className="post-wrapper__title">
								<span>771%</span> ROAS z Facebook Ads i{" "}
								<span>951%</span> ROAS z Google Ads w 6 miesięcy
								dla nowego sklepu e-commerce
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/nowy-sklep-ecommerce/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/ecommerce-marketing-case-2.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								sklep e-commerce - branża kosmetyczna
							</p>
							<p className="post-wrapper__title">
								Wzrost przychodów z 8813 zł do{" "}
								<span>389 372 zł</span> miesięcznie z kampanii
								produktowej Google Ads
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/perfumeria-euforia/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-contact">
				<div className="form-wrapper">
					<h2 className="single-offer-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormDark locales={locales["pl"]} />
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};
export default MarketingDlaEcommercePage;
